import { useCallback, useEffect, useState } from "react";
import Icon from "@portal/components/atoms/icons";
import Typography from "@portal/components/atoms/v2/typography";
import { IUserNotification } from "./types";
import { uuid } from "uuidv4";
import { addUserNotificationListner, removeUserNotificationListner } from "./userMessageChannel";

const UserNotification = () => {
  const [notifications, setNotifications] = useState<Array<IUserNotification>>([]);

  const closeNotification = useCallback(
    (id: string) => {
      setNotifications(n => n.filter(n => n.id !== id));
    },
    [setNotifications],
  );

  useEffect(() => {
    const handleNewNotification = (event: MessageEvent) => {
      try {
        if (event.data) {
          const id = uuid();
          setNotifications(n => [...n, { id, message: event.data.text, orderNumber: event.data.orderNumber }]);
          const audio = new Audio("sound/notification.mp3");
          audio.play();
          setTimeout(() => {
            closeNotification(id);
          }, 5000);
        }
      } catch (e) {
        console.error("broadcast failed, user notification data", e);
      }
    };

    addUserNotificationListner(handleNewNotification);
    return () => {
      removeUserNotificationListner(handleNewNotification);
    };
  }, [closeNotification]);

  if (!notifications || notifications.length === 0) return null;

  return (
    <>
      <div className="hidden bottom-[20px]"></div>
      <div className="hidden bottom-[100px]"></div>
      <div className="hidden bottom-[180px]"></div>
      <div className="hidden bottom-[260px]"></div>
      <div className="hidden bottom-[340px]"></div>
      {notifications
        .reduce((unique, n) => {
          if (!unique.some(obj => obj.orderNumber === n.orderNumber)) {
            unique.push(n);
          }
          return unique;
        }, [] as IUserNotification[])
        .slice(0, 5)
        .map((notifications, index) => {
          const posClass = `bottom-[${20 + 80 * index}px]`;
          return (
            <div
              key={notifications.id}
              className={`fixed ${posClass} right-5 w-96 rounded-lg border border-greyBorder p-1 bg-white z-50`}
            >
              <div className="relative flex justify-between items-center p-4">
                <div className="absolute top-1 right-2">
                  <button onClick={() => closeNotification(notifications.id)}>
                    <Icon name="xMarkIcon" size="small" />
                  </button>
                </div>
                <div className="flex items-center space-x-4">
                  <Icon name="bellAlertIcon" size="small" />
                  <Typography variant="Bold">{notifications.message}</Typography>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default UserNotification;
